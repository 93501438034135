import { Directive, booleanAttribute, input } from '@angular/core';
import { setInitialClasses } from '../util/dom/style.dom';

/**
 * Makes an element show or hide its contents.
 *
 * ```html
 * <my-component [fsnSkeleton]="loading">
 *   <p>This text will fade in when `loading` is false<p>
 * </my-component>
 * ```
 *
 * ```scss
 * @use 'fs-next/loading/skeleton';
 * @include skeleton.skeleton using ($state) {
 *   // $state will be 'hide' when loading is true.
 *   p {
 *     @include skeleton.opacity($state);
 *   }
 * }
 * ```
 */
@Directive({
  selector: '[fsnSkeleton]',
  standalone: true,
  host: {
    '[class.fsn-skeleton-hide]': 'fsnSkeleton()',
  },
})
export class SkeletonDirective {
  readonly fsnSkeleton = input(false, { transform: booleanAttribute });

  constructor() {
    setInitialClasses('fsn-skeleton', 'fsn-skeleton-hide');
  }
}
