import { Renderer2, inject } from '@angular/core';
import { injectNode } from '../injection';

/**
 * Set classes on the host element directly.
 *
 * This is sometimes required because host bindings aren't evaluated until after
 * the element is added to the DOM. Usually it's not needed because `:host`
 * resolves to an attribute selector that _is_ available right from the start.
 * But it is needed when using _enter_ transitions in combination with
 * `::ng-deep`, or for directives that are included via a `hostDirectives` list.
 */
export function setInitialClasses(...tokens: string[]) {
  const renderer = inject(Renderer2);
  const host = injectNode(HTMLElement);
  for (const token of tokens) renderer.addClass(host, token);
}

/**
 * Get a single style property in the context of an element, including cascaded
 * styles and custom properties.
 *
 * @param property a CSS property like `background-color` or `--custom-prop`.
 */
export const getSingleComputedStyle = (elem: HTMLElement | SVGElement, property: string) => {
  const window = elem.ownerDocument.defaultView;
  if (!window) throw new Error('Element has no associated window');
  const styles = window.getComputedStyle(elem);
  return styles.getPropertyValue(property).trim();
};

/**
 * Apply a set of styles to an element. Call the returned function to reset to the original styles.
 *
 * @returns a function to reset the styles to how they were.
 */
export const temporarilyOverrideStyles = (
  element: HTMLElement | SVGElement,
  properties: Record<string, string>,
): (() => void) => {
  const style = element.style;
  const oldProps = Object.keys(properties).map(name => ({
    name,
    value: style.getPropertyValue(name),
    priority: style.getPropertyPriority(name),
  }));

  const undo = () => oldProps.forEach(({ name, value, priority }) => style.setProperty(name, value, priority));

  try {
    overrideStyles(element, properties);
  } catch (e) {
    undo();
    throw e;
  }

  return undo;
};

/**
 * Forcibly override styles (with !important).
 */
export const overrideStyles = (element: HTMLElement | SVGElement, properties: Record<string, string>) =>
  Object.entries(properties).forEach(([name, value]) => element.style.setProperty(name, value, 'important'));
