import { NgModule } from '@angular/core';
import { SkeletonDirective } from './skeleton.directive';
import { SpinnerComponent } from './spinner.component';

const publish = [SkeletonDirective, SpinnerComponent];

@NgModule({
  imports: publish,
  exports: publish,
})
export class LoadingModule {}
